import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Alert } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddServices = ({ onClose }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [data, setData] = useState(null);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!title || !image || !description) {
      setError('Title, description, and image are required.');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('image', image);

    axios.post('https://rasmasa.com/api/admin/our-services', formData)
      .then(response => {
        setLoading(false);
        setData(response.data);
        // setSuccessMessage('Service created successfully!');
        onClose(); // Close the modal after successful submission
      })
      .catch(error => {
        setLoading(false);
        // setError('Failed to create service. Please try again.');
        console.error('Error creating service:', error);
      });
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      setSuccessMessage(null);
    }
  }, [successMessage]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      if (data.status === true) {
        toast.success('Service created successfully!');
      } else if (data.status === false) {
        toast.error('Failed to create service. Please try again.');
      }
    }
  }, [data]);

  return (
    <div>
      <Form onSubmit={handleSubmit} className='mx-4'>
        <Form.Group controlId="formTitle">
          <Form.Label className='pt-2'>العنوان</Form.Label>
          <Form.Control
            type="text"
            placeholder="كتابة العنوان"
            value={title}
            className='w-100 border-black'
            onChange={handleTitleChange}
          />
        </Form.Group>

        <Form.Group controlId="formDescription" className="mt-3">
          <Form.Label className='pt-4'>الوصف</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="الوصف"
            value={description}
            className='w-100 border-black'
            onChange={handleDescriptionChange}
          />
        </Form.Group>

        <Form.Group controlId="formImage" className="mt-3">
          <Form.Label className='pt-4'>الصوره</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            className='w-100 border-black'
            onChange={handleImageChange}
          />
        </Form.Group>

        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        {successMessage && <Alert variant="success" className="mt-3">{successMessage}</Alert>}
        
        <div className='mb-3'>
          <Button variant="primary" type="submit" className="mt-3" disabled={loading}>
            {loading ? 'Saving...' : 'حفظ'}
          </Button>
        </div>
      </Form>
      <ToastContainer />
    </div>
  );
};

export default AddServices;
