import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { CirclesWithBar } from 'react-loader-spinner';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post('https://rasmasa.com/api/admin/login', {
        email,
        password,
      });

      if (response.data.status === true) {
        localStorage.setItem('token', response.data.data.token);
        toast.success('Successfully logged in! Redirecting to dashboard...');
        setTimeout(() => {
          navigate('/Sliders');
        }, 2000); // Adjust the delay as needed
      } else {
        toast.error('Login failed. Please check your credentials and try again.');
      }
    } catch (error) {
      toast.error('Login failed. Please check your credentials and try again.');
      console.error('Error logging in:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='d-flex justify-content-center align-items-center flex-column w-50 mx-auto mt-140  border border-black'>
    
    {loading?   
<div className='pt5 pb-5 mt-5'>
<CirclesWithBar
  height="100"
  width="100"
  className='pt-4 pb-4'
  color="#4fa94d"
  outerCircleColor="#4fa94d"
  innerCircleColor="#4fa94d"
  barColor="#4fa94d"
  ariaLabel="circles-with-bar-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />  </div>
  :<>
      <h2 className='pt-4 '>تسجيل دخول</h2>
      <form onSubmit={handleSubmit} className='pt-3 w-100 px-4'>
        <div >
          <label>البريد الاكتروني:</label>
          <input
            type="email"
            value={email}
            className='w-100 form-control border border-black mt-3'
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className='mt-4'>
          <label>الرقم السري:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className='w-100 form-control border border-black mt-3'
            required
          />
        </div>
        <button className='btn bg-dark text-white mt-4 mb-4' type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'تسجيل دخول'}
        </button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
  </>}
    
    
    
      <ToastContainer />
    </div>
  );
};

export default Login;
