import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function View({id}) {
    const [data, setdata] = useState([])
    console.log(id)



    useEffect(() => {
        axios.get(`https://rasmasa.com/api/admin/our-services/${id}`)
          .then(response => {
            setdata(response?.data?.data);
          console.log(response.data.data , "datadatadata");
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
  return <>
  
  <div className="d-flex mx-3 mt-4 mb-2">
    <p className='fw-bold'>العنوان</p>
    <p className='mx-4'>{data.title}</p>

  </div>
  <div className="d-flex mx-3 mt-2 mb-4">
    <p className='fw-bold'>الوصف</p>
    <p className='mx-4'>{data.description}</p>

  </div>

  
  </>
}
