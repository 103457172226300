import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import ModeSliders from './ModeSliders';
import ModelCreateSlider from './ModelCreateSlider';
import UpdateDate from './UpdateDate';
import 'react-toastify/dist/ReactToastify.css';
import View from './View';

const Sliders = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [sho, setSho] = useState(false);
  const [sh, setSh] = useState(false);
  const [s, setS] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setSelectedId(id);
    setShow(true);
  };

  const handleClos = () => setSho(false);
  const handleSho = () => setSho(true);

  const handleClo = () => setSh(false);
  const handleSh = (id) => {
    setSelectedId(id);
    setSh(true);
  };

  const handleCl = () => setS(false);
  const handleS = (id) => {
    setSelectedId(id);
    setS(true);
  };

  useEffect(() => {
    axios.get('https://rasmasa.com/api/admin/sliders')
      .then(response => {
        setData(response.data?.data || []);
      })
      .catch(error => {
        console.error('Error fetching data : ', error);
      });
  }, [data]);

  
  const columns = [
    {
      name: 'العنوان',
      selector: row => row.title,
      sortable: true,
    },
    {
      name: 'الصور',
      cell: row => (
        <img src={`https://rasmasa.com/api/${row?.image}`} alt="" className='img-fluid imgtable' />
      ),
      sortable: true,
    },
    {
      name: 'المعاملات',
      cell: row => (
        <>
          <Button variant="danger" className='btn btn-danger' size="sm" onClick={() => handleShow(row.id)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Button>
          <Button variant="warning" className='btn btn-warning text-white mx-3' size="sm" onClick={() => handleSh(row.id)}>
            <i className="fas fa-edit"></i>
          </Button>
          <Button variant="warning" className='btn  text-white mx-3 bg-success border' size="sm" onClick={() => handleS(row.id)}>
          <i class="fas fa-eye"></i>
          </Button>
        </>
      ),
      sortable: true,
    },
  ];

  return (
    <div className='mt-5'>
      <div>
        <Button variant="success" className='btn btn-success mx-3' size="sm" onClick={handleSho}>
          <i className="fa-solid fa-plus px-2"></i>
          اضافة
        </Button>
      </div>
      <DataTable
        columns={columns}
        data={data}
        selectableRows
        pagination
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className='d-flex justify-content-between'>
          <Modal.Title>هل تريد حذف الصوره</Modal.Title>
        </Modal.Header>
        <div className="d-flex justify-content-between mx-4">
          <ModeSliders id={selectedId} />
          <button onClick={handleClose} className='btn btn-dark btnh-10 mt-4 px-4'>لا</button>
        </div>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal show={sho} onHide={handleClos}>
        <Modal.Header closeButton className='d-flex justify-content-between'>
          <Modal.Title>اضافة بيانات</Modal.Title>
        </Modal.Header>
        <ModelCreateSlider />
      </Modal>

      <Modal show={sh} onHide={handleClo}>
        <Modal.Header closeButton className='d-flex justify-content-between'>
          <Modal.Title>تعديل البيانات</Modal.Title>
        </Modal.Header>
        <UpdateDate id={selectedId} onClose={handleClo} />
      </Modal>

      <Modal show={s} onHide={handleCl}>
        <Modal.Header closeButton className='d-flex justify-content-between'>
          <Modal.Title>تعديل البيانات</Modal.Title>
        </Modal.Header>
        <View   id={selectedId} />
        {/* يمكنك استخدام مكون تعديل آخر هنا إذا لزم الأمر */}
      </Modal>
    </div>
  );
};

export default Sliders;
