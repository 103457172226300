import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

import 'react-toastify/dist/ReactToastify.css';
import CreateWork from './CreateWork';
import DeleteWork from './DeleteWork';
import UpdateeWork from './UpdateWork';
import View from './View';
const Works = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setSelectedId(id);
    setShow(true);
  };


  const [sho, setSho] = useState(false);
  const [selectedI, setSelectedI] = useState(null);
  const handleClos = () => setSho(false);
  const handleSho = (id) => {
    setSelectedId(id);
    setSho(true);
  };

  const [s, setS] = useState(false);
  // const [selectedI, setSelectedI] = useState(null);
  const handleCl = () => setS(false);
  const handleS = (id) => {
    setSelectedId(id);
    setS(true);
  };

  const [sh, setSh] = useState(false);
  const [selected, setSelected] = useState(null);
  const handleClo = () => setSh(false);
  const handleSh = (id) => {
    setSelectedId(id);
    setSh(true);
  };

  useEffect(() => {
    axios.get('https://rasmasa.com/api/admin/our-works')
      .then(response => {
        setData(response.data?.data || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [data]);

  const columns = [
    {
      name: 'العنوان',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'الصور',
      cell: row => (
        <img src={`https://rasmaback.sahla-eg.com/${row?.image}`} alt="" className='img-fluid imgtable' />
      ),
      sortable: true,
    },
    {
      name: 'المعاملات',
      cell: row => (
   <>
   

   
   <Button variant="delete " className=' btn btn-danger' size="sm" onClick={() => handleShow(row.id)}>
   <i class="fa fa-trash" aria-hidden="true"></i>
        </Button>
   <Button variant="delete " className=' btn btn-warning text-white mx-3' size="sm" onClick={() => handleSh(row.id)}>
   <i class="fas fa-edit"></i>
        </Button>
   <Button variant="delete " className=' btn bg-success border text-white mx-3' size="sm" onClick={() => handleS(row.id)}>
   <i class="fas fa-eye"></i>
        </Button>
        
        
        
        </>
      ),
      sortable: true,
    },
  ];

  return (
    <div className='mt-5'>
      <div>
      <Button variant="delete " className=' btn btn-success mx-3' size="sm" onClick={() => handleSho()}>
          <i className="fa-solid fa-plus px-2"></i>
          اضافة
        </Button>
      </div>
      <DataTable
        columns={columns}
        data={data}
        selectableRows
        pagination
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className='d-flex justify-content-between'>
          <Modal.Title>   هل تريد حذف الصوره </Modal.Title>
        </Modal.Header>
  <div className="d-flex justify-content-between mx-4">
  <DeleteWork id={selectedId} />
  <button onClick={handleClose} className='btn btn-dark btnh-10 mt-4 px-4'>لا</button>
       
  </div>
        <Modal.Footer>
      
      
        </Modal.Footer>
      </Modal>
      <Modal show={sho} onHide={handleClos}>
        <Modal.Header closeButton className='d-flex justify-content-between'>
          <Modal.Title> اضافة بيانات  </Modal.Title>
        </Modal.Header>
        
        <CreateWork id={selectedId} />
      
      </Modal>



      <Modal show={sh} onHide={handleClo}>
        <Modal.Header closeButton className='d-flex justify-content-between'>
          <Modal.Title> تعديل البيانات  </Modal.Title>
        </Modal.Header>

<UpdateeWork id={selectedId} />

      </Modal>


      <Modal show={s} onHide={handleCl}>
        <Modal.Header closeButton className='d-flex justify-content-between'>
          <Modal.Title>   عرض البيانات </Modal.Title>
        </Modal.Header>

<View id={selectedId} />

      </Modal>
    </div>
  );
};

export default Works;
