import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
// import ModeSliders from './ModeSliders';
// import ModelCreateSlider from './ModelCreateSlider';
// import UbdateDate from './UpdateDate';
import 'react-toastify/dist/ReactToastify.css';
import UpdateDate from './UbdateWhoAreWe';
import View from './View';
const WhoAreWe = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setSelectedId(id);
    setShow(true);
  };


  const [sho, setSho] = useState(false);
  const [selectedI, setSelectedI] = useState(null);
  const handleClos = () => setSho(false);
  const handleSho = (id) => {
    setSelectedId(id);
    setSho(true);
  };

  const [sh, setSh] = useState(false);
  const [selected, setSelected] = useState(null);
  const handleClo = () => setSh(false);
  const handleSh = (id) => {
    setSelectedId(id);
    setSh(true);
  };

  useEffect(() => {
    axios.get('https://rasmasa.com/api/admin/who-are-we')
      .then(response => {
        setData(response.data?.data || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const columns = [
    {
      name: 'العنوان',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'الوصف 1',
      selector: row => row.description,
      sortable: true,
    },
    {
      name: 'رقم 1',
      selector: row => row.number1,
      sortable: true,
    },
    {
      name: 'الوصف 2',
      selector: row => row.description2,
      sortable: true,
    },
    {
      name: 'رقم 2',
      selector: row => row.number2,
      sortable: true,
    },
    {
      name: 'الوصف 3',
      selector: row => row.description3,
      sortable: true,
    },
    {
      name: 'رقم 3',
      selector: row => row.number3,
      sortable: true,
    },
    {
      name: 'الصور',
      cell: row => (
        <img src={`https://rasmaback.sahla-eg.com/${row?.image}`} alt="" className='img-fluid imgtable' />
      ),
      sortable: true,
    },
    {
      name: 'المعاملات',
      cell: row => (
   <>
   

   
   <Button variant="delete " className='  btn bg-success border text-white  ' size="sm" onClick={() => handleShow(row.id)}>
   <i class="fas fa-eye"></i>
        </Button>
   <Button variant="delete " className=' btn btn-warning text-white mx-3' size="sm" onClick={() => handleSh(row.id)}>
   <i class="fas fa-edit"></i>
        </Button>
        
        
        
        </>
      ),
      sortable: true,
    },
  ];

  return (
    <div className='mt-5'>
      <div>
      {/* <Button variant="delete " className=' btn btn-success mx-3' size="sm" onClick={() => handleSho()}>
          <i className="fa-solid fa-plus px-2"></i>
          اضافة
        </Button> */}
      </div>
      <DataTable
        columns={columns}
        data={data}
        selectableRows
        pagination
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className='d-flex justify-content-between'>
          <Modal.Title>   عرض البيانات    </Modal.Title>
        </Modal.Header>
  <div className=" mx-4">
  <View id={selectedId} />
  </div>
        <Modal.Footer>
      
      
        </Modal.Footer>
      </Modal>
      <Modal show={sho} onHide={handleClos}>
        <Modal.Header closeButton className='d-flex justify-content-between'>
          <Modal.Title> اضافة بيانات  </Modal.Title>
        </Modal.Header>
        {/* <ModelCreateSlider id={selectedId} /> */}
      
      </Modal>



      <Modal show={sh} onHide={handleClo}>
        <Modal.Header closeButton className='d-flex justify-content-between'>
          <Modal.Title> تعديل البيانات  </Modal.Title>
        </Modal.Header>

          <UpdateDate id={selectedId} />

      </Modal>
    </div>
  );
};

export default WhoAreWe;
