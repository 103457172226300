import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function View({id}) {
    const [data, setdata] = useState([])
    console.log(id )



    useEffect(() => {
        axios.get(`https://rasmasa.com/api/admin/contact-us/${id}`)
          .then(response => {
            setdata(response?.data?.data);
          console.log(response.data.data , "datadatadata");
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
  return <>
  
  <div className="d-flex mx-3 mt-4 mb-4 w-100">
    <p className='fw-bold'>الاسم الاول</p>
    <p className='mx-4'>{data.first_name}</p>
  </div>
  <div className="d-flex mx-3 mt-4 mb-4">
    <p className='fw-bold'>الاسم الاخير</p>
    <p className='mx-4'>{data.last_name}</p>
  </div>
  <div className="d-flex mx-3 mt-4 mb-4">
    <p className='fw-bold'> البريد الاكتروني</p>
    <p className='mx-4'>{data.email}</p>
  </div>
  <div className="d-flex mx-3 mt-4 mb-4">
    <p className='fw-bold'>  الرساله</p>
    <p className='mx-4'>{data.message}</p>
  </div>

  
  </>
}
