import React, { useState } from 'react';
import axios from 'axios';
import { Button, Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeleteWork = ({ id, onClose }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    axios.delete(`https://rasmasa.com/api/admin/our-works/${id}`,{
        _method:"delete"
    })
      .then(response => {
        console.log('Image deleted:', response.data);
        toast.success('Image successfully deleted.'); // Show success toast
        setLoading(false);
        // onClose(); // Close the modal after successful deletion
      })
      .catch(error => {
        console.error('Error deleting image:', error);
        toast.error('Failed to delete the image. Please try again.'); // Show error toast
        setLoading(false);
      });
  };

  return (
    <div className="p-4">
      <Button variant="danger" onClick={handleDelete} disabled={loading}>
        {loading ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{' '}
            Deleting...
          </>
        ) : (
          'نعم'
        )}
      </Button>
      <ToastContainer />
    </div>
  );
};

export default DeleteWork;
