import React, { useState } from 'react';

export default function Meta() {
  const [metaData, setMetaData] = useState({
    value_description: '',
    value_keywords: '',
    value_author: ''
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMetaData({
      ...metaData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    // قم بإرسال البيانات إلى الـ API هنا
    fetch('https://rasamsa.co/api/public/api/admin/updatemeta', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(metaData),
    })
      .then(response => response.json())
      .then(data => {
        // console.log('Success:', );
        setLoading(false);
        setSuccessMessage('Data updated successfully.');
        setMetaData({
          value_description: '',
          value_keywords: '',
          value_author: ''
        });
      })
      .catch(error => {
        console.error('Error:', error);
        setLoading(false);
        setErrorMessage('An error occurred while updating data.');
      });
  };

  return (
    <div className='w-75 mx-auto'>
            {successMessage && <p className='text-success mt-3'>{successMessage}</p>}
      {errorMessage && <p className='text-danger mt-3'>{errorMessage}</p>}
    
      <h2>تعديل بيانات Meta</h2>
      <form onSubmit={handleSubmit}>
        <div className='mt-4'>
          <label htmlFor="value_description" className='fw-bold mt-4'>Description:</label>
          <input
            type="text"
            id="value_description"
            placeholder='Description'
            className='form-control border border-black mt-2'
            name="value_description"
            value={metaData.value_description}
            onChange={handleChange}
          />
        </div>
        <div className='mt-4'>
          <label htmlFor="value_keywords" className='mt-4 fw-bold'>Keywords:</label>
          <input
            type="text"
            id="value_keywords"
            placeholder='Keywords'
            name="value_keywords"
            className='form-control border border-black mt-2'
            value={metaData.value_keywords}
            onChange={handleChange}
          />
        </div>
        <div className='mt-4'>
          <label htmlFor="value_author" className='mt-4 fw-bold'>Author:</label>
          <input
            type="text"
            id="value_author"
            name="value_author"
            placeholder='Author'
            className='form-control border border-black mt-2'
            value={metaData.value_author}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className='btn btn-dark mt-3' disabled={loading}>
          {loading ? 'Loading...' : 'Submit'}
        </button>
      </form>


    </div>
  );
}
